import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,3,4,5,6];

export const dictionary = {
		"/": [~8],
		"/cal/[scheduleId]/[participantId]": [9,[,3],[2]],
		"/cal/[scheduleId]/[participantId]/success": [10,[,3],[2]],
		"/dashboard": [11,[4]],
		"/dashboard/actions": [12,[4]],
		"/dashboard/actions/[actionId]/[sessionId]": [13,[4]],
		"/dashboard/billing": [~14,[4]],
		"/dashboard/billing/success": [15,[4]],
		"/dashboard/calendar-log": [16,[4]],
		"/dashboard/chat": [17,[4]],
		"/dashboard/contacts": [18,[4]],
		"/dashboard/emails": [19,[4]],
		"/dashboard/emails/[[folderExec]]/[folder]": [20,[4,5]],
		"/dashboard/exec/[execId]": [21,[4]],
		"/dashboard/schedule": [22,[4]],
		"/dashboard/schedule/new": [24,[4]],
		"/dashboard/schedule/[scheduleId]": [23,[4]],
		"/dashboard/settings": [~25,[4]],
		"/dashboard/settings/calendar/[execId]": [26,[4]],
		"/dashboard/tasks": [27,[4]],
		"/dashboard/tasks/new": [30,[4]],
		"/dashboard/tasks/[agendaId]": [28,[4]],
		"/dashboard/tasks/[agendaId]/view": [29,[4]],
		"/dashboard/team": [31,[4]],
		"/dashboard/team/add": [32,[4]],
		"/internal": [33,[6]],
		"/internal/organizations": [~34,[6]],
		"/internal/users": [~35,[6]],
		"/invite/[orgId]/[inviteId]": [~36],
		"/login": [37],
		"/welcome": [38,[7]],
		"/welcome/create-organization": [39,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';